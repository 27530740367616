<template>
  <section>
    <b-card class="card-app-design assigned-staff-card">
      <h4>Student Registration Link</h4>
      <b-button size="sm" variant="outline-primary" class="btn-tour-skip mr-1"
        @click="openLink(data.student_registration_link)">
        <span class="mr-25 align-middle">Click Here</span>
      </b-button>
    </b-card>

    <button id="counseling-button" class="float" @click="toggleChatbot" v-if="user_type === 'franchise'">
      <!-- <i class="bi bi-chat-fill"></i> -->
      <img src="@/assets/images/avatars/admin-new-new.png" alt="admin" width="30" class="rounded-cirlce" />
    </button>

    <div v-if="showChatbot" class="chatbot-box">
      <div class="chat-header">
        <h5 class="mb-0 text-white">Chat with us</h5>
        <button @click="toggleChatbot" style="background: transparent; border: none;"><svg fill="#000000" width="20px"
            height="20px" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
            <g id="SVGRepo_iconCarrier">
              <path
                d="M0 14.545L1.455 16 8 9.455 14.545 16 16 14.545 9.455 8 16 1.455 14.545 0 8 6.545 1.455 0 0 1.455 6.545 8z"
                fill-rule="evenodd"></path>
            </g>
          </svg></button>
      </div>
      <div class="chat-body" ref="chatBody">
        <div v-if="messages.length" class="highlight-box">
          <div v-for="(message, index) in messages" :key="index" class="chat-message d-flex"
            :class="{ 'justify-content-end': message.type === 'user', 'justify-content-start': message.type === 'bot' }">
            <div :class="message.type">{{ message.text }}</div>
          </div>
          <div v-if="loading">
            <div class="chat-bubble">
              <div class="typing">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
              </div>
            </div>
          </div>

        </div>
        <div v-else class="h-100">
          <div class="d-flex justify-content-center align-items-center h-100 flex-column">
            <h4>Talk with Max</h4>
            <p class="text-center">Hey! I'm Max — your business partner for managing leads, invoices, and commissions. Let's grow together!" 💼💸</p>
          </div>
        </div>
      </div>
      <div class="chat-footer">
        <input v-model="userInput" type="text" class="border-0 chat-input" placeholder="Type your message..." />
        <button @click="sendMessage" class="btn"
          style="border-radius: 0.25rem !important;background-color: #000;">Send</button>
      </div>
    </div>

    <div v-if="['franchise', 'counselor'].includes(user_type)">
      <b-row class="mb-3">
        <b-col style="height: 440px" cols="12">
          <BarChart ref="barChart" />
        </b-col>
      </b-row>
      <b-row class="mb-3">
        <b-col style="height: 440px" cols="6">
          <b-card title="Earnings" class="b_card_earning" style="height: 100%">
            <div class="selected_filter" v-if="activeSlideIndex === 0">
              <b>{{ selectedFilter }}</b>
            </div>

            <div class="b_header_earning" v-if="activeSlideIndex === 0">
              <div class="d-flex align-items-center justify-content-between">
                <div></div>
                <div class="filters mr-2">
                  <span v-for="filter in filters" :key="filter" :class="{ active: selectedFilterName === filter }"
                    class="filter_text" @click="selectFilter(filter)">
                    {{ filter }}
                  </span>
                </div>
              </div>
              <div class="d-flex flex-column align-items-center">
                <date-range-picker @update="handleDateUpdate" :locale-data="locale" :opens="opens" :ranges="ranges"
                  :autoApply="autoApply" v-model="dateRange" class="date-range-picker">
                </date-range-picker>
              </div>
            </div>

            <swiper :modules="modules" :slides-per-view="1" :space-between="70" :pagination="{
              el: '.swiper-pagination',
              clickable: true,
            }" :navigation="{
                nextEl: '.custom-next',
                prevEl: '.custom-prev',
              }" v-if="user_type" ref="swiper" @swiper="onSwiper" @slideChange="onSlideChange">
              <swiper-slide class="">
                <EarningChart :series="series" :totalEarnings="totalEarnings" :isChartDataReady="isChartDataReady"
                  @goToOtherIncome="goToOtherIncome" />
              </swiper-slide>
              <swiper-slide class="">
                <ServiceBifuChart :date="date" @goToService="goToService" />
              </swiper-slide>
              <swiper-slide :callApi:="package_bifurcation" class="">
                <PackagesChart :service_id="service_id" />
              </swiper-slide>
            </swiper>
            <div class="swiper-pagination"></div>
          </b-card>
        </b-col>
        <b-col style="height: 440px" cols="6">
          <b-card title="Expenses" class="h-100">
            <div class="selected_filter">
              <b>{{ me_selectedFilter }}</b>
            </div>

            <div class="b_header_earning">
              <div class="d-flex align-items-center justify-content-between">
                <div></div>
                <div class="filters mr-2">
                  <span v-for="filter in me_filters" :key="filter" :class="{ active: me_selectedFilterName === filter }"
                    class="filter_text" @click="me_selectFilter(filter)">
                    {{ filter }}
                  </span>
                </div>
              </div>
            </div>
            <b-tabs nav-class="">
              <b-tab active>
                <template #title class="p-0">
                  <h5 class="font-small-4">Expenses</h5>
                </template>
                <b-row class="mt-2">
                  <b-col cols="12" v-for="expense in monthlyExpenses" :key="expense.id">
                    <div class="
                        d-flex
                        align-items-center
                        justify-content-between
                        mb-2
                      ">
                      <div class="d-flex align-items-center">
                        <feather-icon :icon="expense.icon" size="25" />
                        <h3 class="font-medium-3 ml-1" style="color: #333">
                          {{
                            expense.type
                              .replace(/_/g, " ")
                              .replace(/\w\S*/g, function (txt) {
                                return (
                                  txt.charAt(0).toUpperCase() +
                                  txt.substr(1).toLowerCase()
                                );
                              })
                          }}
                        </h3>
                      </div>
                      <h3 class="font-medium-2" style="">
                        Rs. {{ expense.amount }}
                      </h3>
                    </div>
                  </b-col>

                  <b-col cols="12">
                    <hr class="w-100" />
                  </b-col>
                  <b-col cols="12">
                    <div class="
                        d-flex
                        align-items-center
                        justify-content-between
                        mb-1
                      ">
                      <h3 class="font-medium-4" style="color: #333">Total</h3>
                      <h3 class="font-medium-2">
                        Rs. {{ totalMonthlyExpenses }}
                      </h3>
                    </div>
                  </b-col>
                </b-row>
              </b-tab>

              <b-tab>
                <template #title class="p-0">
                  <h5 class="font-small-4">Add Expense</h5>
                </template>
                <MonthlyExpenses :month="me_month" :year="me_year" @refreshBarChart="refreshBarChart"
                  :user_type="user_type" />
              </b-tab>
            </b-tabs>
          </b-card>
        </b-col>
      </b-row>
      <div>
        <StatisticCard v-if="user_type" :user_type="user_type" />
        <div v-if="!user_type">Loading Statistics .....</div>
      </div>
    </div>

    <staff-home />

    <div class="row">
      <!-- <div class="col-lg-12"></div> -->

      <div class="col-lg-12">
        <OtherDetails :data="data" />
      </div>
    </div>
    <b-row> </b-row>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BCardTitle,
  BCardHeader,
  BForm,
  BFormGroup,
  BFormInput,
  BTabs,
  BTab,
} from "bootstrap-vue";
import { SwiperCore, Swiper, SwiperSlide } from "swiper-vue2";
import "swiper/swiper-bundle.css";
import { Navigation, Pagination } from "swiper";

SwiperCore.use([Navigation, Pagination]);
import moment from "moment";

import { FILESURL } from "@/config";
import AgentServices from "@/apiServices/AgentServices";
import store from "@/store";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import StatisticCard from "./Resources/StatisticCard.vue";
import EarningChart from "./Resources/EarningChart.vue";
import ServiceBifuChart from "./Resources/ServiceBifuChart.vue";
import StaffHome from "../StaffHome/StaffHome.vue";
import OtherDetails from "./helper/OtherDetails.vue";
import BarChart from "./Resources/BarChart.vue";
import CommonServices from "@/apiServices/CommonServices";
import PackagesChart from "./Resources/FranchiseTrainingVideos/PackagesChart.vue";
import MonthlyExpenses from "../Settings/MonthlyExpenses.vue";
import CounselorServices from "@/apiServices/CounselorServices";
import user from "@/store/user";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BCardTitle,
    BCardHeader,
    StatisticCard,
    EarningChart,
    Swiper,
    SwiperSlide,
    ServiceBifuChart,
    DateRangePicker,
    StaffHome,
    OtherDetails,
    BarChart,
    PackagesChart,
    BForm,
    BFormGroup,
    BFormInput,
    BTabs,
    BTab,
    MonthlyExpenses,
  },
  data() {
    const startDate = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      1
    );
    const endDate = new Date();
    return {
      month: moment().format("MMMM"),
      year: new Date().getFullYear(),
      service_id: null,
      swiperInstance: null,
      modules: [Navigation, Pagination],
      FILESURL,
      data: {},
      series: [0, 0],
      totalEarnings: 0,
      isChartDataReady: false,
      isBifurChartDataReady: false,
      isEarningChartLoaded: false,
      package_bifurcation: false,
      // spaceBetween: 60,
      maxDate: new Date(),
      locale: { firstDay: 1, format: " " },
      ranges: {
        // Today: [new Date(), new Date()],
        "This Week": [
          new Date(
            new Date().setDate(new Date().getDate() - new Date().getDay())
          ),
          new Date(),
        ],
        "Last Week": [
          new Date(
            new Date().setDate(new Date().getDate() - new Date().getDay() - 7)
          ),
          new Date(
            new Date().setDate(new Date().getDate() - new Date().getDay() - 1)
          ),
        ],
        "This Month": [
          new Date(new Date().getFullYear(), new Date().getMonth(), 1),
          new Date(),
        ],
        "This Year": [
          new Date(new Date().getFullYear(), 0, 1),
          new Date(new Date().getFullYear(), 11, 31),
        ],
        "This Quarter": [
          new Date(
            new Date().getFullYear(),
            Math.floor(new Date().getMonth() / 3) * 3,
            1
          ),
          new Date(
            new Date().getFullYear(),
            Math.floor(new Date().getMonth() / 3) * 3 + 3,
            0
          ),
        ],
        Lifetime: [null, null],
      },
      quarter: null,
      opens: "left",
      autoApply: true,
      date: `${moment(
        new Date(new Date().getFullYear(), new Date().getMonth(), 1)
      ).format("YYYY-MM-DD")} to ${moment(new Date()).format("YYYY-MM-DD")}`,
      dateRange: {
        startDate: moment(startDate).toDate(),
        endDate: moment(endDate).toDate(),
      },
      selectedFilterName: "This Month",
      selectedFilter: moment().format("MMMM"),
      filters: ["This Month", "This Quarter", "This Year", "Lifetime"],
      monthlyExpenses: [],
      totalMonthlyExpenses: 0,
      me_selectedFilterName: "This Month",
      me_selectedFilter: moment().format("MMMM"),
      me_filters: ["This Month", "This Quarter", "This Year", "Lifetime"],
      me_month: moment().format("MMMM"),
      me_year: new Date().getFullYear(),
      account_created_at: null,
      activeSlideIndex: 0,
      showChatbot: false,
      loading: false,
      userInput: '',
      messages: [],
      session_status: false,
    };
  },
  methods: {
    toggleChatbot() {
      this.showChatbot = !this.showChatbot;
      this.scrollToBottom();
    },
    async retrieveSessionMessages() {
    try {
      const response = await AgentServices.getPineconeSession();
      if (response.data.status && response.data.data.isActive) {
        this.session_status = true;
        this.messages = response.data.data.messages;
      }
    } catch (error) {
      console.error("Error retrieving session messages:", error);
    }
  },
    async sendMessage() {
      try {
        if (this.userInput.trim() === '') return;
        const payload = {
          input: this.userInput
        };
        // Add user message to messages array
        this.messages.push({ text: this.userInput, type: 'user' });
        this.userInput = ''; // Clear the input after sending the message
        this.loading = true;
        const response = await AgentServices.pineconeAssistant(payload);
        if (response.status) {
          // Add assistant message to messages array
          this.messages.push({ text: response.data.data.message.content, type: 'bot' });
        }
      } catch (error) {
        console.error("Error sending message:", error);
      } finally {
        this.loading = false;
      }
    },
    scrollToBottom() {
      setTimeout(() => {
        this.$nextTick(() => {
        const chatBody = this.$refs.chatBody;
        chatBody.scrollTo({
          top: chatBody.scrollHeight,
          behavior: 'smooth'
        });
      });
      }, 200);
    },
    moment,
    onSlideChange() {
      if (this.swiperInstance) {
        this.activeSlideIndex = this.swiperInstance.activeIndex;
      }
    },
    async getFranchiseStatistics() {
      try {
        this.isChartDataReady = false;

        const payload = { dateRange: this.date };
        let res;
        if (this.user_type === "franchise") {
          res = await AgentServices.getFranchiseStatistics(payload);
        }
        if (this.user_type === "counselor") {
          res = await CommonServices.getFranchiseStatistics({
            franchise_id: this.franchise_id,
            ...payload,
          });
        }
        if (res.data.status) {
          this.account_created_at = res.data.data.created_at_date;

          this.franchise_statistics = res.data.data.statistics;
          this.series = [
            this.franchise_statistics.total_aliff_commission,
            this.franchise_statistics.total_service_commission,
          ];

          this.totalEarnings =
            this.franchise_statistics.total_aliff_commission +
            this.franchise_statistics.total_service_commission;
        }


        this.isChartDataReady = true;
      } catch (error) {
        console.log("Error in getting getFranchiseStatistics Agent ", error);
      }
    },

    handleDateUpdate(value) {
      const selectedRange = Object.entries(this.ranges).find(
        ([name, range]) =>
          range[0]?.toDateString() === value.startDate?.toDateString() &&
          range[1]?.toDateString() === value.endDate?.toDateString()
      );
      this.selectedFilterName = selectedRange
        ? selectedRange[0]
        : "Custom Range";

      if (value.startDate === null && value.endDate === null) {
        this.dateRange = { startDate: "", endDate: "" };
        this.date = "Lifetime";
      } else {
        const formattedStartDate = moment(value.startDate).format("YYYY-MM-DD");
        const formattedEndDate = moment(value.endDate).format("YYYY-MM-DD");
        this.dateRange = {
          startDate: formattedStartDate,
          endDate: formattedEndDate,
        };
        this.date = `${formattedStartDate} to ${formattedEndDate}`;
      }

      if (this.selectedFilterName === "This Month") {
        this.selectedFilter = moment().format("MMMM");
      }

      if (this.selectedFilterName === "This Quarter") {
        this.selectedFilter = `Q${Math.floor(new Date().getMonth() / 3) + 1}`;
      }

      if (this.selectedFilterName === "This Year") {
        this.selectedFilter = new Date().getFullYear();
      }
      if (this.selectedFilterName === "Lifetime") {
        this.selectedFilter =
          new Date(this.account_created_at).toLocaleString("default", {
            year: "numeric",
            month: "short",
          }) +
          " - " +
          new Date().toLocaleString("default", {
            year: "numeric",
            month: "short",
          });
      }

      this.getFranchiseStatistics();
    },

    async getHomeData() {
      try {
        const res = await AgentServices.getHomeData();
        if (res.data.status) {
          this.data = res.data.data;
        }
      } catch (err) {
        console.log("Error in getting getHomeData Agent ", err);
      }
    },
    openLink(link) {
      window.open(link, "_blank");
    },
    selectFilter(filter) {
      this.selectedFilterName = filter;

      let startDate, endDate;
      switch (filter) {
        case "This Month":
          startDate = new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            1
          );
          endDate = new Date();
          this.dateRange = {
            startDate: moment(startDate).toDate(),
            endDate: moment(endDate).toDate(),
          };
          break;
        case "This Quarter":
          startDate = new Date(
            new Date().getFullYear(),
            Math.floor(new Date().getMonth() / 3) * 3,
            1
          );
          endDate = new Date(
            new Date().getFullYear(),
            Math.floor(new Date().getMonth() / 3) * 3 + 3,
            0
          );
          this.dateRange = {
            startDate: moment(startDate).toDate(),
            endDate: moment(endDate).toDate(),
          };
          this.quarter = `Q${Math.floor(new Date().getMonth() / 3) + 1}`;
          break;
        case "This Year":
          startDate = new Date(new Date().getFullYear(), 0, 1);
          endDate = new Date(new Date().getFullYear(), 11, 31);
          this.dateRange = {
            startDate: moment(startDate).toDate(),
            endDate: moment(endDate).toDate(),
          };
          break;
        case "Lifetime":
          startDate = null;
          endDate = null;
          break;
        default:
          return;
      }

      const dateRange = {
        startDate: startDate ? moment(startDate).toDate() : null,
        endDate: endDate ? moment(endDate).toDate() : null,
      };

      this.handleDateUpdate(dateRange);
    },
    me_selectFilter(filter) {
      this.me_selectedFilterName = filter;

      let month;
      let year = new Date().getFullYear();
      switch (filter) {
        case "This Month":
          month = new Date().toLocaleString("default", { month: "long" });
          this.me_selectedFilter = month;
          break;
        case "This Quarter":
          const currentMonth = new Date().getMonth();
          if (currentMonth >= 0 && currentMonth <= 2) {
            month = "January,February,March";
          } else if (currentMonth >= 3 && currentMonth <= 5) {
            month = "April,May,June";
          } else if (currentMonth >= 6 && currentMonth <= 8) {
            month = "July,August,September";
          } else if (currentMonth >= 9 && currentMonth <= 11) {
            month = "October,November,December";
          }
          this.me_selectedFilter = `Q${Math.floor(new Date().getMonth() / 3) + 1
            }`;
          break;
        case "This Year":
          month = null;
          this.me_selectedFilter = year;
          break;
        case "Lifetime":
          month = null;
          year = null;
          this.me_selectedFilter =
            new Date(this.account_created_at).toLocaleString("default", {
              year: "numeric",
              month: "short",
            }) +
            " - " +
            new Date().toLocaleString("default", {
              year: "numeric",
              month: "short",
            });
          break;
        default:
          return;
      }
      this.me_month = month;
      this.me_year = year;
    },

    onSwiper(swiper) {
      this.swiperInstance = swiper; // Save the Swiper instance when initialized
    },
    goToService(service_id) {
      this.service_id = service_id;
      this.swiperInstance.slideTo(2);
      this.package_bifurcation = true;
    },

    goToOtherIncome() {
      this.swiperInstance.slideTo(1);
    },
    async getServiceRevenueById(service_id, franchise_id) {
      try {
        const payload = {
          service_id: service_id,
          franchise_id: franchise_id,
        };
        const res = await CommonServices.getServiceRevenueById(payload);
        if (res && res.data.status) {
          console.log("servicesData", res);
        }
      } catch (error) {
        console.error("Error fetching service revenue:", error);
      }
    },
    async getMonthlyExpenses() {
      try {
        const payload = {
          month: this.me_month,
          year: this.me_year,
        };
        const res = await AgentServices.getMonthlyExpenses(payload);
        if (res.data.status) {
          const monthlyExpenses = res.data.data;
          let rent = 0;
          let salary = 0;
          let marketing = 0;
          let other = 0;
          let incentives = 0;
          for (let i = 0; i < monthlyExpenses.length; i++) {
            switch (monthlyExpenses[i].me_type) {
              case "rent":
                rent = rent + monthlyExpenses[i].me_amount;
                break;
              case "salary":
                salary = salary + monthlyExpenses[i].me_amount;
                break;
              case "incentives":
                incentives = incentives + monthlyExpenses[i].me_amount;
                break;
              case "marketing":
                marketing = marketing + monthlyExpenses[i].me_amount;
                break;
              case "other":
                other = other + monthlyExpenses[i].me_amount;
                break;
            }
          }

          this.monthlyExpenses = [
            {
              type: "Salary",
              amount: salary,
              icon: "DollarSignIcon",
            },
            {
              type: "Incentives",
              amount: incentives,
              icon: "TrendingUpIcon",
            },
            {
              type: "Rent",
              amount: rent,
              icon: "HomeIcon",
            },
            {
              type: "Marketing",
              amount: marketing,
              icon: "CastIcon",
            },
            {
              type: "Other",
              amount: other,
              icon: "PackageIcon",
            },
          ];
          const total = rent + salary + incentives + marketing + other;
          this.totalMonthlyExpenses = total;
        }
      } catch (error) {
        console.error("Error in getMonthlyExpenses ", error);
      }
    },
    refreshBarChart() {
      this.$refs.barChart.refreshBarChart();
    },
  },

  computed: {
    user_type() {
      if (store.getters["user/getUserDetails"]?.user_type) {
        return store.getters["user/getUserDetails"].user_type;
      }
    },
    user_id() {
      if (store.getters["user/getUserDetails"]) {
        return store.getters["user/getUserDetails"].user_id;
      }
    },
  },
  watch: {
    me_month() {
      this.getMonthlyExpenses();
    },
    me_year() {
      this.getMonthlyExpenses();
    },
  },
  beforeMount() {
    this.getHomeData();
    if(this.user_type === 'franchise') {
      this.retrieveSessionMessages();
    }

    this.getMonthlyExpenses();
    if (this.user_type) {
      this.getFranchiseStatistics();
    }
    // this.getLMSAccess();
  },
};
</script>

<style scoped>
.dashboard__heading {
  color: #002884;
  font-weight: bold;
  margin-bottom: 1.2rem;
}

.student-dashboard .card-title {
  color: #000000;
  font-weight: 600;
}

.primary-color {
  color: #00d09c;
}

.swiper-container {
  height: auto !important;
}

.vue-daterange-picker * span {
  display: none !important;
}

.filters {
  display: flex;
  gap: 10px;
}

.filters span {
  cursor: pointer;
  padding-bottom: 5px;
}

.filters span.active {
  border-bottom: 2px solid #00d09c;
}

.filter_text {
  font-size: smaller;
  font-weight: 700;
  opacity: 0.7;
  margin-left: 5px;
  padding-bottom: 2px;
}

.vue-daterange-picker[data-v-1ebd09d2] {
  position: relative;
  display: inline-block;
  min-width: 20px !important;
}

.b_card_earning {
  position: relative;
}

.b_header_earning {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 18px;
  right: 22px;
}

@media screen and (max-width: 1400px) {
  .b_header_earning {
    /* position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 50px;
  right: 0px; */
  }
}

.custom-swiper-container {
  position: relative;
  width: 100%;
  max-width: 600px;
  margin: auto;
}

.slide-content {
  text-align: center;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 10px;
}

/* Custom Arrows */
.custom-navigation {
  display: flex;
  justify-content: space-around;
  width: 100%;
  /* margin-bottom: 10px;; */
}

.custom-prev,
.custom-next {
  background: #d6e3f0;
  color: rgb(7, 7, 7);
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 0 5px;
}

.custom-prev:hover,
.custom-next:hover {
  cursor: pointer;
}

.swiper-pagination {
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 8px;
}

.swiper-pagination-bullet {
  background-color: #92c5f9;
  width: 12px;
  height: 12px;
  opacity: 0.7;
  border-radius: 50%;
}

.swiper-pagination-bullet-active {
  background-color: #4394e5;
  opacity: 1;
}

.selected_filter {
  position: absolute;
  top: 22px;
  left: 120px;
}

@media screen and (max-width: 1200px) {
  .swiper-slide {
    width: 100% !important;
  }
}

@media screen and (max-width: 1600px) {
  .swiper-slide {
    width: 100% !important;
  }
}

@media screen and (max-width: 1400px) {
  .swiper-slide {
    width: 100% !important;
  }
}
</style>
<style scoped>
.user {
  text-align: right;
  color: black;
  padding: 6px 10px;
  background: #5367ff29;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  width: fit-content;
}

.bot {
  text-align: left;
  color: #000;
  padding: 6px 10px;
  background: #00d09c73;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  width: fit-content;
}

.float {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: linear-gradient(135deg, #5367ff 0%, #00d09c 100%);
  border: none;
  border-radius: 50%;
  padding: 22px 26px;
  cursor: pointer;
  z-index: 99999;
}

.chatbot-box {
  position: fixed;
  bottom: 10px;
  right: 20px;
  width: 400px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  z-index: 999999;
}

.chat-header {
  background: linear-gradient(135deg, #5367ff 0%, #00d09c 100%);
  color: white;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.chat-input:focus {
  outline: none;
}

.chat-body {
  height: 400px;
  overflow-y: auto;
  padding: 10px;
}

.chat-footer {
  display: flex;
  border-top: 1px solid #eee;
  padding: 10px;
}

.chat-footer input {
  flex: 1;
  padding: 5px;
}

.chat-footer button {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 5px 10px;
}

.chat-message {
  margin-bottom: 10px;
}

.user {
  text-align: right;
  color: black;
}

.chat-bubble {
  background-color: #E6F8F1;
  padding: 10px 28px;
  -webkit-border-radius: 20px;
  -webkit-border-bottom-left-radius: 2px;
  -moz-border-radius: 20px;
  -moz-border-radius-bottomleft: 2px;
  border-radius: 20px;
  border-bottom-left-radius: 2px;
  display: inline-block;
}

.typing {
  align-items: center;
  display: flex;
  height: 17px;
}

.typing .dot {
  animation: mercuryTypingAnimation 1.8s infinite ease-in-out;
  background-color: #6CAD96;
  border-radius: 50%;
  height: 7px;
  margin-right: 4px;
  vertical-align: middle;
  width: 7px;
  display: inline-block;
}

.typing .dot:nth-child(1) {
  animation-delay: 200ms;
}

.typing .dot:nth-child(2) {
  animation-delay: 300ms;
}

.typing .dot:nth-child(3) {
  animation-delay: 400ms;
}

.typing .dot:last-child {
  margin-right: 0;
}

@keyframes mercuryTypingAnimation {
  0% {
    transform: translateY(0px);
    background-color: #6CAD96;
  }

  28% {
    transform: translateY(-7px);
    background-color: #9ECAB9;
  }

  44% {
    transform: translateY(0px);
    background-color: #B5D9CB;
  }
}
</style>